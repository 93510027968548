import axios, { AxiosResponse } from "axios";
import { ResidentialResponse } from "../types/IdentificationApi/ResidentialResponse";
import { mockedData } from "../mocks/ResidentialInfo";

class ResidentialInfoService {
  private readonly API_URL = `${process.env.ecomBff}`;
  private readonly RESIDENTIAL_INFO_ENDPOINT = "/identifications/customer/residential";

  // Defining a Map to store previously retrieved residential
  // information for a given CPF.
  private readonly cpfResults: Map<string, ResidentialResponse> = new Map();

  constructor() { return }

  /**
   * This method is used to consult the residential information
   * of a customer by their CPF.
   * @param cpf The CPF of the customer to be consulted.
   */
  public async getResidentialInfo(cpf: string): Promise<ResidentialResponse> {
    if (cpf in mockedData) {
      return mockedData[cpf];
    }

    // Checking if the residential information for the given CPF
    // has already been retrieved and stored in the Map.
    if (this.cpfResults.has(cpf)) {
      const result = this.cpfResults.get(cpf);
      if (result) {
        return result;
      }
    }

    try {
      const response: AxiosResponse<ResidentialResponse> = await axios.get(
        `${this.API_URL}${this.RESIDENTIAL_INFO_ENDPOINT}?document=${cpf}`
      );
      // Storing the retrieved residential information in the Map
      // for future use.
      const residentialInfo = response.data;
      this.cpfResults.set(cpf, residentialInfo);

      return residentialInfo;
    } catch (error) {
      return error;
    }
  }
}

export default new ResidentialInfoService();
